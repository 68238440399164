import Wrapper from "./wrapper";
import axios from "axios";
import {config} from "../config";
import decode from 'jwt-decode';
import UserWrapper from "./user.wrapper";
import PatientWrapper from "./patient.wrapper";
import ProfessionalWrapper from "./professional.wrapper";
import ParametricsWrapper from "./parametrics.wrapper";

axios.interceptors.request.use((config) => {
	config.headers['request-startTime'] = new Date(Date.now()).getTime()
	return config
})

axios.interceptors.response.use((response) => {
	const start = response.config.headers['request-startTime']
	const end = new Date(Date.now()).getTime()
	const seconds = (end - start) / 1000; 
	response.headers['request-duration'] = seconds
	return response
})

class UserProfileWrapper extends Wrapper {
	constructor() {
		super();
	};

	async getPatientsQueue(jwtToken, specialtyId) {
		try {
			const response = await axios.get(
				`${config.api.user_profile.USERPROFILE_QUEUE_ENDPOINT}/${specialtyId}`,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return response.data;
		} catch (error) {
			error.message = `Error mdw getPatientsQueue: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
		}
	}

	async getPatientsQueueOther(jwtToken, specialtyId) {
		try {
			const response = await axios.get(
				`${config.api.user_profile.USERPROFILE_QUEUE_ENDPOINT}/now/${specialtyId}`,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return response.data;
		} catch (error) {
			error.message = `Error mdw getPatientsQueueOther: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
		}
	}

	async getAllPatientQueuesWaitingAccepted(jwtToken, specialtyId) {
		try {
			const response = await axios.get(
				`${config.api.user_profile.USERPROFILE_QUEUE_ENDPOINT}/waiting-accepted/${specialtyId}`,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return response.data;
		} catch (error) {
			error.message = `Error mdw getAllPatientQueuesWaitingAccepted: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
		}
	}

	async PostSpeedTestMobile(jwtToken){
		try {
		  const downloadStartTime = new Date().getTime();

		  // Download the file
		  const downloadResponse = await fetch(
			`${config.api.user_profile.USER_SPEEDTEST_API_ENDPOINT}/download`,
			{headers: {...this.commonFileHeaders, authorization: `Bearer ${jwtToken}`}},
		  );
		  const fileData = await downloadResponse.blob();

		  const endTimeDownload = new Date().getTime();
		  const downloadTime = (endTimeDownload - downloadStartTime) / 1000;

		  // Upload the file
		  const uploadStartTime = new Date().getTime();
		  const formData = new FormData();
		  formData.append('file', {
			string: JSON.stringify(fileData),
			type: 'application/json',
		  });

		  const uploadResponse = await fetch(
			`${config.api.user_profile.USER_SPEEDTEST_API_ENDPOINT}/upload`,
			{
			  method: 'POST',
			  headers: {...this.commonFileHeaders, authorization: `Bearer ${jwtToken}`},
			  body: formData,
			},
		  );
		  const endTimeUpload = new Date().getTime();
		  const uploadTime = (endTimeUpload - uploadStartTime) / 1000;
		  return {
			downloadTime,
			uploadTime,
		  };
		} catch (error) {
		  console.error('Error occurred during download and upload:', error);
		  throw error;
		}
	}

	async getSpeedTestTime(jwtToken) {
		try{
			const responseTime = await axios.get(
				`${config.api.user_profile.USER_SPEEDTEST_API_ENDPOINT}/time`,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return responseTime.data;
		}catch (error) {
			error.message = `Error mdw getSpeedTestTime: ${error.message}`;
			console.log(error);
			if(error.response.status != 200) {
				return [];
			} else throw error;
        }
	}

	async postSpeedTest(jwtToken) {
		try {
			let download = 0;
			let upload = 0;
			let time_upload = 0
			let time_download = 0
			let size = 0;
			const responseDownload = await axios.get(
				`${config.api.user_profile.USER_SPEEDTEST_API_ENDPOINT}/download`,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`, responseType: 'blob'}}
			);
			const file = new File([responseDownload.data], 'download.bin', { type: "text/plain" });
			//Size file in bytes
			size = responseDownload.headers['content-length'] || 2097152;
			//Time Download in seconds
			time_download = responseDownload.headers['request-duration']
			//Download in Mbps
			download = ((size / (1024 * 1024)) / responseDownload.headers['request-duration']) * 8
    		let formData = new FormData();
			formData.append("file", file);
			const responseUpload = await axios.post(
				`${config.api.user_profile.USER_SPEEDTEST_API_ENDPOINT}/upload`,
				formData,
				{headers: {...this.commonFileHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			//Time Upload in seconds
			time_upload = responseUpload.headers['request-duration']
			//Upload in Mbps
			upload = ((size / (1024 * 1024)) / responseUpload.headers['request-duration']) * 8
			return {download: download, time_download: time_download, upload: upload, time_upload, size: size};
		} catch (error) {
			error.message = `Error mdw postSpeedTest: ${error.message}`;
			console.log(error);
			if(error.response.status != 200) {
				return [];
			} else throw error;
        }
	}

	async getUserProfile(jwtToken) {
		try {
			const decodedToken = decode(jwtToken);

			const log = new ParametricsWrapper();
			await log.eventLog(jwtToken, 'getUserProfile', decodedToken.auth, decodedToken);

			let response = {};
			switch (decodedToken.auth) {
				case 'ROLE_PATIENT':
					const pw = new PatientWrapper();
					response = await pw.getPatientProfile(jwtToken, decodedToken.sub);
					break;
				case 'ROLE_PROFESSIONAL':
					const pfw = new ProfessionalWrapper();
					response = await pfw.getProfessionalProfile(jwtToken);
					break;
				case 'ROLE_PHARMACIST':
					//get pharmacist profile
					break;
				default:
					throw new Error('Unauthorized role');
			}

			await log.eventLog(jwtToken, 'getUserProfile', decodedToken.auth, response);

			return response;
		} catch (error) {
			error.message = `Error getting user profile: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
	};

	async getUserId(jwtToken, userLogin) {
		try {
			const response = await axios.get(
				`${config.api.gateway.GATEWAY_USER_ENDPOINT}/byLogin/${userLogin}`,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return response.data;
		} catch (error) {
			error.message = `Error mdw getUserId: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
	}

	async getPatientStudy(jwtToken, userLogin) {
		try {
			const response = await axios.get(
				`${config.api.user_profile.PACIENT_STUDY_ENDPOINT}/${userLogin}`,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return response.data;
		} catch (error) {
			error.message = `Error mdw getPatientStudy: ${error.message}`;
			console.log(error);
			if(error.response.status != 200) {
				return [];
			} else throw error;
        }
	}

	async getPatientMemberStudy(jwtToken, userLogin, idNumber) {
		try {
			const response = await axios.get(
				`${config.api.user_profile.PACIENT_MEMBER_STUDY_ENDPOINT}/${userLogin}/${idNumber}`,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return response.data;
		} catch (error) {
			error.message = `Error mdw getPatientStudy: ${error.message}`;
			console.log(error);
			if(error.response.status != 200) {
				return [];
			} else throw error;
        }
	}

	async putPatientStudy(jwtToken, userLogin, file) {
		try {
			const response = await axios.put(
				`${config.api.user_profile.PACIENT_STUDY_ENDPOINT}/${userLogin}`, file,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return response.data;
		} catch (error) {
			error.message = `Error mdw getPatientStudy: ${error.message}`;
			console.log(error);
			if(error.response.status != 200) {
				return [];
			} else throw error;
        }
	}

	async postPatientStudy(jwtToken, userLogin, file) {
		try {
			const response = await axios.put(
				`${config.api.user_profile.PACIENT_STUDY_ENDPOINT}/${userLogin}`,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}, file}
			);
			return response.data;
		} catch (error) {
			error.message = `Error mdw getPatientStudy: ${error.message}`;
			console.log(error);
			if(error.response.status != 200) {
				return [];
			} else throw error;
        }
	}

	async putPatientMemberStudy(jwtToken, userLogin, file, idNumber) {
		try {
			const response = await axios.put(
				`${config.api.user_profile.PACIENT_MEMBER_STUDY_ENDPOINT}/${userLogin}/${idNumber}`, file,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return response.data;
		} catch (error) {
			error.message = `Error mdw getPatientStudy: ${error.message}`;
			console.log(error);
			if(error.response.status != 200) {
				return [];
			} else throw error;
        }
	}

	async postPatientMemberStudy(jwtToken, userLogin, file, idNumber) {
		try {
			const response = await axios.put(
				`${config.api.user_profile.PACIENT_MEMBER_STUDY_ENDPOINT}/${userLogin}/${idNumber}`,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}, file}
			);
			return response.data;
		} catch (error) {
			error.message = `Error mdw getPatientStudy: ${error.message}`;
			console.log(error);
			if(error.response.status != 200) {
				return [];
			} else throw error;
        }
	}

	async userFirstRegister(body) {
		return await new UserWrapper().userRegister(body);
	};

	async getAccount(jwtToken) {
		try {
			const response = await axios.get(
				config.api.gateway.GATEWAY_ACCOUNT_ENDPOINT,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return response.data;
		} catch (error) {
			error.message = `Error getting gateway account: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
	};

	async setUserData(jwtToken, body) {
		try {
			const response = await axios.post(
				config.api.user_profile.USER_DATA_ENDPOINT,
				body,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return response.data;
		} catch (error) {
			error.message = `Error mdw setUserData: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
	}
}


export default UserProfileWrapper;
